import { render, staticRenderFns } from "./SdkScan.vue?vue&type=template&id=53022655&scoped=true&"
import script from "./SdkScan.vue?vue&type=script&lang=js&"
export * from "./SdkScan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53022655",
  null
  
)

export default component.exports